import {
  createBrowserRouter,
  Outlet,
  RouterProvider,
  Navigate,
} from "react-router-dom";
import { Suspense, lazy, useContext } from "react";
import { darkModeContext } from "./context/darkModeContext";
import { AuthContext } from "./context/authContext";

// General styles
import "./style.scss";

// Fonts
import "./fonts/Rubik-Light.ttf";
import "./fonts/Rubik-Regular.ttf";
import "./fonts/Rubik-Medium.ttf";
import "./fonts/Rubik-SemiBold.ttf";
import "./fonts/Rubik-Bold.ttf";

// Loading Screen
import Overlay from "./components/loadingScreens/Overlay";
import CreateNewPassword from "./pages/createNewPassword/CreateNewPassword";

// Components
const Navbar = lazy(() => import("./components/navBar/Navbar"));
// const HelpCenterNavbar = lazy(() =>
//   import("./components/helpCenterNavBar/HelpCenterNavBar")
// );
// const HelpCenterLeftbar = lazy(() =>
//   import("./components/helpCenterLeftBar/HelpCenterLeftBar")
// );
// const HelpCenterFooter = lazy(() =>
//   import("./components/helpCenterFooter/HelpCenterFooter")
// );
const AdvertsLeftBar = lazy(() =>
  import("./components/advertsLeftbar/AdvertsLeftBar")
);

// Pages
const Login = lazy(() => import("./pages/login/Login"));
const Signup = lazy(() => import("./pages/signup/Signup"));
const ResetUserPassword = lazy(() =>
  import("./pages/resetPassword/ResetPassword")
);

// const ContactUs = lazy(() => import("./pages/helpFolder/contactUs/ContactUs"));
// const HelpCenterMenu = lazy(() =>
//   import("./pages/helpFolder/helpCenterMenu/HelpCenterMenu")
// );
const ReportProblem = lazy(() =>
  import("./pages/helpFolder/reportAdProblem/ReportProblem")
);

//Adverts pages
const AdsOverview = lazy(() => import("./pages/AdsOverview/AdsOverview"));
const AdsCampaign = lazy(() => import("./pages/adsCampaign/AdsCampaign"));
const CreateAd = lazy(() => import("./pages/createAd/CreateAd"));
const FundAccount = lazy(() => import("./pages/fundAccount/FundAccount"));
const AccountSettings = lazy(() =>
  import("./pages/adAccountSettings/AccountSettings")
);
const UserAdDetails = lazy(() => import("./pages/adDetails/UserAdDetails"));

function App() {
  const { darkMode } = useContext(darkModeContext);
  const { loggedInUser } = useContext(AuthContext);

  // Create my page view layout
  const Layout = () => {
    return (
      <div className={`theme-${darkMode ? "dark" : "light"}`}>
        {/* <Overlay/> */}
       
        <Navbar />
        <div style={{ display: "flex" }}>
          <AdvertsLeftBar />
          <Outlet />
        </div>
      </div>
    );
  };

  const ProtectedRoute = ({ children }) => {
    if (!loggedInUser) {
      return <Navigate to="/login" />;
    }

    return children;
  };

  // Apply my view layout to each page links
  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <ProtectedRoute>
          <Layout />
        </ProtectedRoute>
      ),
      children: [
        {
          path: "/",
          element: (
            <Suspense fallback={<Overlay />}>
              <AdsOverview />
            </Suspense>
          ),
        },
        {
          path: "/campaigns/:id",
          element: (
            <Suspense fallback={<Overlay />}>
              <AdsCampaign />
            </Suspense>
          ),
        },
        {
          path: "/create-ad",
          element: (
            <Suspense fallback={<Overlay />}>
              <CreateAd />
            </Suspense>
          ),
        },
        {
          path: "/fund-account/:id",
          element: (
            <Suspense fallback={<Overlay />}>
              <FundAccount />
            </Suspense>
          ),
        },
        {
          path: "/ad-details/:id",
          element: (
            <Suspense fallback={<Overlay />}>
              <UserAdDetails />
            </Suspense>
          ),
        },
        {
          path: "/account-settings/:id",
          element: (
            <Suspense fallback={<Overlay />}>
              <AccountSettings />
            </Suspense>
          ),
        },
        {
          path: "/report-ad-problem",
          element: (
            <Suspense fallback={<Overlay />}>
              <ReportProblem />
            </Suspense>
          ),
        },
      ],
    },

    // General router link to all href links
    {
      path: "/login",
      element: (
        <Suspense fallback={<Overlay />}>
          <Login />
        </Suspense>
      ),
    },
    {
      path: "/signup",
      element: (
        <Suspense fallback={<Overlay />}>
          <Signup />
        </Suspense>
      ),
    },
    {
      path: "/reset-user-password",
      element: (
        <Suspense fallback={<Overlay />}>
          <ResetUserPassword />
        </Suspense>
      ),
    },
    {
      path: "/create-new-password/:resetCode",
      element: (
        <Suspense fallback={<Overlay />}>
          <CreateNewPassword />
        </Suspense>
      ),
    },
  ]);

  // Load my app to be visible on the browser
  return (
    <div className="App">
      <Suspense fallback={<Overlay />}>
        <RouterProvider router={router} />
      </Suspense>
    </div>
  );
}

export default App;
